import { send } from "./Api";

const baseUrl = () => document.config.templateApiPublic;

const getByShortCode = async code => {
  const request = {
    path: `${baseUrl()}/shortcode/${code}`,
    method: "get"
  };
  return send(request);
};

export default {
  getByShortCode
};
