<template>
  <ion-content class="prime-compliance-page">
    <div class="prime-compliance-page-container">
      <ion-row class="ion-align-items-center">
        <ion-col size="1">
          <ion-icon @click="back" name="md-arrow-back" class="icon-back cursor-pointer"></ion-icon>
        </ion-col>
      </ion-row>
      <img class="mypcp-logo" src="../assets/logo.svg" />
      <h2>{{ pageTitle }}</h2>
      <div v-if="loading" class="text-center pad-ten full-height margin-top-twenty">
        <div class="centerItems text-muted display-flex valign full-height full-width">
          <ion-spinner name="lines" class="spinner-large valign"></ion-spinner>
        </div>
      </div>
      <p v-else v-html="pageBody" class="margin-top-thirty"></p>
      <footerCompliance class="pad-top-twenty"></footerCompliance>
    </div>
  </ion-content>
</template>

<script>
import TemplateApi from "@/services/TemplateApi";
import FooterCompliance from "@/components/Global/FooterCompliance";

export default {
  name: "CompliancePublic",
  components: {
    FooterCompliance
  },
  props: {
    pageTitle: String,
    pageContentCode: String
  },
  data() {
    return {
      pageBody: undefined,
      loading: true
    };
  },
  async created() {
    if (this.$route.query.type) {
      try {
        const { data } = await TemplateApi.getByShortCode(this.$route.query.type);
        this.pageBody = data.templateMessages.map(tm => tm.body).join("");
        this.loading = false;
      } catch (error) {
        khanSolo.log(error);
        this.$ionic.toastController
          .create({
            header: "Failed to load page content",
            message: error,
            duration: 5000,
            position: "top"
          })
          .then(m => m.present());
      }
    } else {
      this.$router.push({
        name: "EnrollmentInfo"
      });
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped>
.prime-compliance-page {
  background: var(--ion-background-color);
}
.icon-back {
  font-size: 28px;
  vertical-align: middle;
  margin-left: -4px;
}
.prime-compliance-page-container {
  max-width: 780px;
  margin: 30px auto;
  padding: 0 20px;
}
.prime-compliance-page-container .mypcp-logo {
  width: 300px;
  display: block;
  margin: auto;
}
.prime-compliance-page-container h1 {
  margin-bottom: 30px;
}
@media screen and (max-width: 540px) {
  .prime-compliance-page-container .mypcp-logo {
    width: 220px;
  }
}
</style>
